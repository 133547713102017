import React, { useState } from "react";
import { Box, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { init, sendForm } from "emailjs-com";

const ContactThree = (props) => {
  const [namefield, setNameField] = useState();
  const [emailField, setEmailField] = useState();
  const [subjectField, setSubjectField] = useState();
  const [messageField, setMessageField] = useState();
  const toast = useToast();

  init("user_yeBE8zzplCDMQRtKhmpm1");
  const { register, handleSubmit, reset} = useForm();

  const [contactNumber, setContactNumber] = useState("000000");

  const generateContactNumber = () => {
    const numStr = "000000" + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };

  const onSubmit = () => {
    generateContactNumber();
    setNameField(namefield);
    setEmailField(emailField);
    setSubjectField(subjectField);
    setMessageField(messageField);
    sendForm(
      "service_yv3r3ao",
      "template_k7vxcmq",
      "#contact-form",
      "user_yeBE8zzplCDMQRtKhmpm1"
    ).then(
      function (response) {
        reset();
        console.log("SUCCESS!", response.status, response.text);
        toast({
          position: "top-left",
          render: () => (
            <Box mt={20} color="white" p={10} bg="green" alignItems="center">
              Email sent
            </Box>
          ),
        });
      },
      function (error) {
        console.log("FAILED...", error);
        toast({
          position: "top-left",
          render: () => (
            <Box mt={20} color="white" p={10} bg="#F62828" alignItems="center">
              Something went wrong
            </Box>
          ),
        });
      }
    );
  };

  return (
    <div className="contact-form--1">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="section-title text-left mb--50">
              <h2 className="title">{props.contactTitle}</h2>
              <p className="description">
                I am booked until 2022 but available for future work. Reach out
                to me via the form below if you're interested in working with
                me.
                <br />
                <br />
                <b>My email:</b> AntoineMincy@gmail.com
              </p>
            </div>
            <div className="form-wrapper">
              <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="hidden"
                  name="contact_number"
                  value={contactNumber}
                />
                <label htmlFor="item01">
                  <h4>
                    <em>*All Fields Required</em>
                  </h4>
                  <input
                    type="text"
                    name="user_name"
                    maxLength="30"
                    id="item01"
                    value={namefield}
                    onChange={(namefield) => setNameField(namefield)}
                    placeholder="Your name *"
                    {...register("user_name", {
                      required: "Please enter your name.",
                    })}
                  />
                </label>

                <label htmlFor="item02">
                  <input
                    type="text"
                    name="user_email"
                    id="item02"
                    maxLength="30"
                    value={emailField}
                    onChange={(emailField) => setEmailField(emailField)}
                    placeholder="Your email *"
                    {...register("user_email", {
                      required: "Please enter your email.",
                    })}
                  />
                </label>

                <label htmlFor="item03">
                  <input
                    type="text"
                    name="user_subject"
                    id="item03"
                    maxLength="30"
                    value={subjectField}
                    onChange={(subjectField) => setSubjectField(subjectField)}
                    placeholder="Write a Subject *"
                    {...register("user_subject", {
                      required: "Please enter a subject.",
                    })}
                  />
                </label>
                <label htmlFor="item04">
                  <textarea
                    type="text"
                    id="item04"
                    name="user_message"
                    value={messageField}
                    onChange={(messageField) => setMessageField(messageField)}
                    placeholder="Your Message"
                    {...register("user_message", {
                      required: "Please enter a message.",
                    })}
                  />
                </label>
                <button
                  className="rn-button-style--2 btn-solid"
                  type="submit"
                  value="submit"
                  name="submit"
                  id="mc-embedded-subscribe"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
            <div className="thumbnail mb_md--30 mb_sm--30">
              <img src={`${props.contactImages}`} alt="SimplyMincy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactThree;
